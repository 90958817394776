<template>
  <div>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="4">
          <label
            for="search"
          >Pesquisar</label>
          <b-form-input
            id="state"
            v-model="search"
          />
        </b-col>
        <b-col md="4">
          <label
            for="local"
          >Local</label>
          <v-select
            id="local"
            v-model="client"
            label="full_name"
            placeholder="Todos os locais"
            :reduce="p => p.id"
            :options="$store.state.quales.clients"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
        <b-col md="4">
          <label
            for="modalidade"
          >Modalidade</label>
          <v-select
            id="modalidade"
            v-model="modalities"
            label="name"
            placeholder="Todas as modalidades"
            :reduce="p => p.id"
            :options="$store.state.quales.modalities"
          />
        </b-col>
      </b-row>
      <b-row class="align-items-end">
        <b-col md="4">
          <label
            for="active"
          >Ativo</label>
          <v-select
            id="active"
            v-model="active"
            :options="statuses"
            :reduce="o => o.value"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
        <b-col md="4">
          <label
            for="datepicker-start"
          >Aula a partir de</label>
          <b-button
            v-if="recent_activity"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{recent_activity = ''}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-start"
            v-model="recent_activity"
            class="form-control"
            :config="config"
          />
        </b-col>
        <b-col
          md="4"
          class="align-bottom"
        >
          <b-link
            :to="{ name: 'students-add' }"
          >
            <b-button
              class="float-right ml-2 mt-2"
              variant="success"
            >
              Novo Aluno
            </b-button>
          </b-link>
          <b-button
            variant="primary"
            class="float-right ml-2 mt-2"
            :disabled="$store.state.students.excelLoading"
            @click="$store.dispatch('students/downloadExcel')"
          >
            <div
              v-if="$store.state.students.excelLoading"
              class="spinner-border"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              Baixar lista
            </div>
          </b-button>
        </b-col>

      </b-row>
    </b-card>

    <b-overlay
      :show="deleteWarning"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          :items="$store.state.students.clients"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item :to="{ name: 'students-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'students-report', params: { id: data.item.id } }">
                <feather-icon icon="BarChart2Icon" />
                <span class="align-middle ml-50">Relatório</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="validarDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <p class="m-0">
                <span class="font-weight-bold">{{ $store.state.students.clients.length }} </span> de <span class="font-weight-bold"> {{ $store.state.students.total }}</span>
              </p>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="$store.state.students.total"
                :per-page="$store.state.students.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Você quer deletar o aluno: <b>{{ toDelete.full_name }} - {{ toDelete.email }}</b> ?
          </p>
          <b-button
            ref="delete"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="deletar"
          >
            Deletar
          </b-button>
          <b-button
            ref="cancel"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="cancelDelete"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BTable, BDropdown, BDropdownItem, BPagination, BRow, BCol, BFormInput, BButton, BLink, BOverlay, BIcon,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BOverlay,
    BIcon,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      modalities: null,
      recent_activity: false,
      active: null,
      client: null,
      statuses: [
        {
          label: 'Ativo',
          value: true,
        },
        {
          label: 'A Validar',
          value: false,
        },
      ],
      toDelete: {},
      deleteWarning: false,
      search: '',
      currentPage: 1,
      perPage: 1,
      totalUsers: 100,
      sortBy: null,
      isSortDirDesc: null,
      tableColumns: [
        {
          key: 'full_name',
          sortable: false,
          label: 'Aluno',
          formatter: title,
        },
        {
          key: 'client_name',
          sortable: false,
          label: 'Cliente',
        },
        {
          key: 'email',
          sortable: false,
          label: 'E-Mail',
        },
        {
          key: 'last_event',
          sortable: false,
          label: 'Última aula',
          formatter: value => (value ? this.dateFormater.format(new Date(value)) : 'Sem aulas'),
        },
        { key: 'actions' },
      ],
      config: {
        locale: Portuguese,
        allowInput: false,
      },
    }
  },
  watch: {
    currentPage(newV) {
      this.$store.commit('students/setPage', newV)
      this.$store.dispatch('students/getClients')
    },
    search(newV) {
      this.$store.commit('students/searchFilter', newV)
      this.$store.dispatch('students/getClients')
    },
    active(newV) {
      this.$store.commit('students/activeUpdate', newV)
      this.$store.dispatch('students/getClients')
    },
    client(newV) {
      this.$store.commit('students/clientFilteredFor', newV)
      this.$store.dispatch('students/getClients')
    },
    recent_activity(newV) {
      this.$store.commit('students/recentActivity', newV)
      this.$store.dispatch('students/getClients')
    },
    modalities(newV) {
      this.$store.commit('students/modalitiesFilteredFor', newV)
      this.$store.dispatch('students/getClients')
    },
  },
  beforeCreate() {
    this.$store.dispatch('quales/getModalities')
    this.$store.commit('students/courseFilter', null)
    this.$store.commit('students/clientFilteredFor', null)
    this.$store.commit('students/activeUpdate', null)
    this.$store.commit('students/searchFilter', '')
    this.$store.dispatch('students/getClients')
    this.$store.dispatch('quales/getClients')
  },
  created() {
    this.recent_activity = this.$store.state.students.recent_activity ? this.$store.state.students.recent_activity : ''
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      // hour: 'numeric',
      // minute: '2-digit',
      // weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
  },
  methods: {
    cancelDelete() {
      this.toDelete = {}
      this.deleteWarning = false
    },
    validarDelete(userId) {
      [this.toDelete] = this.$store.state.students.clients.filter(c => c.id === userId)
      this.deleteWarning = true
    },
    deletar() {
      this.$store.dispatch('students/deleteClient', this.toDelete).then(() => {
        this.toDelete = {}
        this.deleteWarning = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Aluno',
            icon: 'UserCheckIcon',
            variant: 'danger',
            text: 'Aluno deletado',
          },
        })
        this.$store.dispatch('students/getClients')
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aluno',
              icon: 'UserCheckIcon',
              variant: 'warning',
              text: 'Não conseguimos deletar este aluno',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~flatpickr/dist/themes/airbnb.css";
.form-control[readonly] {
  background-color: inherit;
}
</style>
